const coboBonus = {
    'coboBonus.commissionType': '佣金类型',
    'coboBonus.commissionType.salesCommission': 'AE返佣',
    'coboBonus.commissionType.csmPerformance': 'CSM员工和团队绩效',
    'coboBonus.commissionType.bdPerformance': 'BD团队绩效',
    'coboBonus.commissionType.salesPerformance': 'AE团队绩效',
    'coboBonus.downloadExcel': '下载文件',
    'coboBonus.table.name': '姓名',
    'coboBonus.table.value': '金额',
    'coboBonus.table.email': '电子邮箱',
    'coboBonus.setPassword': '设置文件密码',
    'coboBonus.total': '总金额',
    'coboBouns.download.tooltip': '无数据时，不支持下载',
};

export default coboBonus;
