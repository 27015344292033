const withdrawExplorerReborn = {
    'custody.withdrawExplorerReborn.tableHeader.request_id': 'Withdrawal Request ID',
    'custody.withdrawExplorerReborn.tableHeader.org_id': 'Org ID',
    'custody.withdrawExplorerReborn.tableHeader.org_name': 'Org Name',
    'custody.withdrawExplorerReborn.tableHeader.to': 'To',
    'custody.withdrawExplorerReborn.tableHeader.amount': 'Transaction Amount',
    'custody.withdrawExplorerReborn.tableHeader.status': 'Main Status',
    'custody.withdrawExplorerReborn.tableHeader.sub_status': 'Sub Status',
    'custody.withdrawExplorerReborn.tableHeader.type': 'Type',
    'custody.withdrawExplorerReborn.tableHeader.source_type': 'Source',
    'custody.withdrawExplorerReborn.tableHeader.initiator_type': 'Initiator',
    'custody.withdrawExplorerReborn.tableHeader.created_timestamp': 'Created Time',
    'custody.withdrawExplorerReborn.tableHeader.risk_message': 'Risk Information',
    'custody.withdrawExplorerReborn.tableHeader.audit_results': 'Audit Results',
    'custody.withdrawExplorerReborn.tableHeader.audited_by': 'Audited By',
    'custody.withdrawExplorerReborn.tableHeader.actions': 'Actions',

    'custody.withdrawExplorerReborn.filterLabel.type': 'Type',
    'custody.withdrawExplorerReborn.filterLabel.time_range': 'Created Time',
    'custody.withdrawExplorerReborn.filterLabel.org_id': 'Org ID/Name',
    'custody.withdrawExplorerReborn.filterLabel.token_ids': 'Token ID',
    'custody.withdrawExplorerReborn.filterLabel.request_ids': 'Withdrawal Request ID',
    'custody.withdrawExplorerReborn.filterLabel.transaction_hashes': 'Blockchain Transaction ID',
    'custody.withdrawExplorerReborn.filterLabel.cobo_ids': 'Cobo ID',
    'custody.withdrawExplorerReborn.filterLabel.transaction_ids': 'Transaction ID',
    'custody.withdrawExplorerReborn.filterLabel.to_addresses': 'To',
    'custody.withdrawExplorerReborn.filterLabel.status': 'Main Status',
    'custody.withdrawExplorerReborn.filterLabel.sub_status': 'Sub Status',

    'custody.withdrawExplorerReborn.filterPlaceholder.type': 'All Types',
    'custody.withdrawExplorerReborn.filterPlaceholder.time_range.start': 'Filter by Start Time...',
    'custody.withdrawExplorerReborn.filterPlaceholder.time_range.end': 'Filter by End Time...',
    'custody.withdrawExplorerReborn.filterPlaceholder.org_id': 'All Orgs',
    'custody.withdrawExplorerReborn.filterPlaceholder.token_ids': 'All Tokens',
    'custody.withdrawExplorerReborn.filterPlaceholder.request_ids': 'Filter by Request ID...',
    'custody.withdrawExplorerReborn.filterPlaceholder.transaction_hashes': 'Filter by Blockchain Transaction ID...',
    'custody.withdrawExplorerReborn.filterPlaceholder.cobo_ids': 'Filter by Cobo ID...',
    'custody.withdrawExplorerReborn.filterPlaceholder.transaction_ids': 'Filter by Transaction ID',
    'custody.withdrawExplorerReborn.filterPlaceholder.to_addresses': 'Filter By Destination Address...',
    'custody.withdrawExplorerReborn.filterPlaceholder.status': 'All Main Status',
    'custody.withdrawExplorerReborn.filterPlaceholder.sub_status': 'All Sub Status',

    'custody.withdrawExplorerReborn.detail.title': 'Portal Transaction Detail ({transaction_id})',
    'custody.withdrawExplorerReborn.detail.txDetail.title': 'Transaction Detail',
    'custody.withdrawExplorerReborn.detail.txDetail.txAmount': 'Transaction Amount',
    'custody.withdrawExplorerReborn.detail.txDetail.fee': 'Fee',
    'custody.withdrawExplorerReborn.detail.txDetail.type': 'Type',
    'custody.withdrawExplorerReborn.detail.txDetail.requestId': 'Request ID',
    'custody.withdrawExplorerReborn.detail.txDetail.status': 'Main Status',
    'custody.withdrawExplorerReborn.detail.txDetail.txHash': 'Blockchain Transaction ID',
    'custody.withdrawExplorerReborn.detail.txDetail.description': 'Description',
    'custody.withdrawExplorerReborn.detail.txDetail.isLoop': 'Is Loop',
    'custody.withdrawExplorerReborn.detail.txDetail.fromAddr': 'From',
    'custody.withdrawExplorerReborn.detail.txDetail.nonce': 'Nonce',
    'custody.withdrawExplorerReborn.detail.txDetail.source': 'Source',
    'custody.withdrawExplorerReborn.detail.txDetail.coboId': 'Cobo ID',
    'custody.withdrawExplorerReborn.detail.txDetail.walletName': 'Wallet Name',
    'custody.withdrawExplorerReborn.detail.txDetail.walletID': 'Wallet ID',
    'custody.withdrawExplorerReborn.detail.txDetail.subStatus': 'Sub Status',
    'custody.withdrawExplorerReborn.detail.txDetail.confirmedNumber': 'Confirmed Number',
    'custody.withdrawExplorerReborn.detail.txDetail.brc20': 'BRC20',
    'custody.withdrawExplorerReborn.detail.txDetail.toAddr': 'To',
    'custody.withdrawExplorerReborn.detail.txDetail.fiatValue': 'Fiat Value',
    'custody.withdrawExplorerReborn.detail.txDetail.initiator': 'Initiator',
    'custody.withdrawExplorerReborn.detail.txDetail.txId': 'Transaction ID',
    'custody.withdrawExplorerReborn.detail.txDetail.toAddrDetail': 'To Address Detail',
    'custody.withdrawExplorerReborn.detail.txDetail.toAddrDetail.toAddress': 'To Address',
    'custody.withdrawExplorerReborn.detail.txDetail.toAddrDetail.amount': 'Amount',
    'custody.withdrawExplorerReborn.detail.txDetail.toAddrDetail.script': 'Script',
    'custody.withdrawExplorerReborn.detail.txDetail.toAddrDetail.one': '{value} item',
    'custody.withdrawExplorerReborn.detail.txDetail.toAddrDetail.other': '{value} items',
    'custody.withdrawExplorerReborn.detail.txDetail.fundPoolId': 'Fund Pool ID',

    'custody.withdrawExplorerReborn.detail.txProcess.title': 'Transaction Process',
    'custody.withdrawExplorerReborn.detail.txProcess.startWidget.initiator.title': 'Initiation',
    'custody.withdrawExplorerReborn.detail.txProcess.startWidget.initiatorType': 'Initiator Type: {initiator_type}',
    'custody.withdrawExplorerReborn.detail.txProcess.startWidget.initiator': 'Initiator: {initiator}',
    'custody.withdrawExplorerReborn.detail.txProcess.startWidget.createdAt': 'Created At: {created_at}',
    'custody.withdrawExplorerReborn.detail.txProcess.startWidget.updatedAt': 'Updated At: {updated_at}',

    'custody.withdrawExplorerReborn.detail.txProcess.customerWidget.title': 'User Risk Control',
    'custody.withdrawExplorerReborn.detail.txProcess.customerWidget.inProgress': '(in progress)',
    'custody.withdrawExplorerReborn.detail.txProcess.customerWidget.spenderCheck': 'Spender Check ({current}/{total}):',
    'custody.withdrawExplorerReborn.detail.txProcess.customerWidget.approverCheck':
        'Approver Check ({current}/{total}):',

    'custody.withdrawExplorerReborn.detail.txProcess.coboWidget.title': 'Cobo Risk Control',
    'custody.withdrawExplorerReborn.detail.txProcess.coboWidget.approved': 'Approved',
    'custody.withdrawExplorerReborn.detail.txProcess.coboWidget.rejected': 'Rejected',
    'custody.withdrawExplorerReborn.detail.txProcess.coboWidget.proof': 'Proof has been uploaded',
    'custody.withdrawExplorerReborn.detail.txProcess.coboWidget.approvalNode': 'Approval Node',

    'custody.withdrawExplorerReborn.hugeApproveModal.clientAuditRecord': 'Client audit record',
    'custody.withdrawExplorerReborn.hugeApproveModal.spenderCheck': 'Spender Check',
    'custody.withdrawExplorerReborn.hugeApproveModal.audit': 'Audit',

    'custody.withdrawExplorerReborn.detail.txProcess.bcWidget.blockchainProcess.title': 'Blockchain Process',
    'custody.withdrawExplorerReborn.detail.txProcess.bcWidget.replacedBy.title': 'Transaction Is Replaced',
    'custody.withdrawExplorerReborn.detail.txProcess.bcWidget.replacedByType': 'Replaced By Type: {replaced_by_type}',
    'custody.withdrawExplorerReborn.detail.txProcess.bcWidget.replacedByTransactionId':
        'Replaced By Transaction ID: {replaced_by_transaction_id}',
    'custody.withdrawExplorerReborn.detail.txProcess.bcWidget.replacedByTransactionHash':
        'Replaced By Transaction Hash: {replaced_by_transaction_hash}',
    'custody.withdrawExplorerReborn.detail.txProcess.bcWidget.replaced.title': 'Replacing Other Transaction',
    'custody.withdrawExplorerReborn.detail.txProcess.bcWidget.replacedType': 'Replacing Type: {replaced_type}',
    'custody.withdrawExplorerReborn.detail.txProcess.bcWidget.replacedTransactionId':
        'Replacing Transaction ID: {replaced_transaction_id}',
    'custody.withdrawExplorerReborn.detail.txProcess.bcWidget.replacedTransactionHash':
        'Replacing Transaction Hash: {replaced_transaction_hash}',
    'custody.withdrawExplorerReborn.detail.txProcess.resultWidget.status.title': 'Transaction Status',
    'custody.withdrawExplorerReborn.detail.txProcess.resultWidget.status': 'Main Status: ',
    'custody.withdrawExplorerReborn.detail.txProcess.resultWidget.subStatus': 'Sub Status: ',
    'custody.withdrawExplorerReborn.detail.txProcess.resultWidget.timeline.title': 'Transaction Timeline',
    'custody.withdrawExplorerReborn.detail.txProcess.resultWidget.timeline.wip': '(in progress)',
    'custody.withdrawExplorerReborn.detail.txRiskData.title': 'Risk Data',
    'custody.withdrawExplorerReborn.detail.txRiskData.withdrawSuccessCount':
        'Count of Withdraws to Destination Address',
    'custody.withdrawExplorerReborn.detail.txRiskData.oneHourWithdraw': 'Same Asset Withdrawn within One Hour',
    'custody.withdrawExplorerReborn.detail.txRiskData.oneDayWithdraw': 'Same Asset Withdrawn within One Day',
    'custody.withdrawExplorerReborn.detail.txRiskData.oneHourWithdrawCount':
        'Count of Same Asset Withdraw within One Hour',
    'custody.withdrawExplorerReborn.detail.txRiskData.oneDayWithdrawCount':
        'Count of Same Asset Withdraw within One Day',

    'custody.withdrawExplorerReborn.detail.txOrgDetail.title': 'Org Detail',
    'custody.withdrawExplorerReborn.detail.txOrgDetail.orgId': 'Org ID',
    'custody.withdrawExplorerReborn.detail.txOrgDetail.orgName': 'Org Name',
    'custody.withdrawExplorerReborn.detail.txOrgDetail.isIpBound': 'Is IP Bound',
    'custody.withdrawExplorerReborn.detail.txOrgDetail.isCallbackConfirmed': 'Is Callback Confirmed',

    'custody.withdrawExplorerReborn.hugeApproveModal.upload': 'Waiting for proof upload',
    'custody.withdrawExplorerReborn.hugeApproveModal.placeholder': 'Upload proof, up to 500 characters',
    'custody.withdrawExplorerReborn.aproveModal.placeholder':
        'This action carries risks; it is recommended to provide remarks.',
    'custody.withdrawExplorerReborn.terminateModal.title': 'Are you sure to terminate this transaction?',
    'custody.withdrawExplorerReborn.terminateModal.note':
        'The balance will be refunded to the user after the transaction is canceled.',
    'custody.withdrawExplorerReborn.terminateModal.refundTitle': 'Amount to be refunded',
    'custody.withdrawExplorerReborn.terminateModal.refundModal.title': 'Confirm the amount to be refunded',
    'custody.withdrawExplorerReborn.terminateModal.form.refundAmount': 'Transaction Amount',
    'custody.withdrawExplorerReborn.terminateModal.form.refundFee': 'Transaction Fee',
    'custody.withdrawExplorerReborn.terminateModal.form.force':
        'Forced operation (This action involves certain risks, proceed with caution)',
    'custody.withdrawExplorerReborn.taskId': 'Task ID',
    'custody.withdrawExplorerReborn.transactionType': 'Transaction Type',

    'transaction.hugeApproval.modal.title': 'Huge Transaction Approval - Are you sure to approve this transaction?',
    'transaction.hugeApproval.modal.btn': 'Huge Transaction Approval',
};

export default withdrawExplorerReborn;
