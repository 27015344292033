import siderBar from './siderBar';
import adminTeamUser from './admin/teamUser';
import kycAudit from './kycAudit';
import cobo_wallet_page from './cobo_wallet_page';
import pending_approval from './pending_approval';
import transaction_explorer from './transaction_explorer';
import riskControl_ruleManagement from './riskControl_ruleManagement';
import riskControl_menuEvent from './riskControl_menuEvent';
import common from './common';
import publishUpdateMessage from './coboSettings/publishUpdateMessage';
import blockchain from './blockchain';
import approvalSystem from './approvalSystem';
import custody from './custody';
import tss from './tss';
import tools from './tools';
import argus from './argus';
import kyt from './kyt';
import mpcAutoSweeping from './mpcAutoSweeping';
import coboBonus from './coboBonus';

const activite_page = {
    'activite.title.inactive': '欢迎使用阿拉丁',
    'activite.title.active': '用户已经被激活，请直接登录',
    'activite.title.expire': '邀请已失效，请联系管理员重新邀请',
    'activite.title.smallTitle': '账户激活',
    'activite.input.email': '邮箱',
    'activite.input.inviteCode': '激活码',
    'activite.input.password': '密码',
    'activite.input.confirmPassword': '确认密码',
    'activite.text.alreadyActivate': '已经激活 ? ',

    'activite.bind_2fa.title': '欢迎使用阿拉丁',
    'activite.bind_2fa.smallTitle': '绑定谷歌验证',
    'activite.bind_2fa.moment': '使用谷歌验证APP扫描下方二维码',
};

const resetpassword_page = {
    'resetpwd.title': '修改密码',
    'resetpwd.input.email': '邮箱',
    'resetpwd.input.password': '密码',
    'resetpwd.input.confirmPassword': '确认密码',
};

const reset2fa_page = {
    'reset2fa.title': '重新绑定2FA',
    'reset2fa.input.email': '邮箱',
    'reset2fa.input.password': '密码',
    'reset2fa.input.confirmPassword': '确认密码',

    'reset2fa.bind_2fa.title': '欢迎使用阿拉丁',
    'reset2fa.bind_2fa.smallTitle': '绑定谷歌验证',
    'reset2fa.bind_2fa.moment': '使用谷歌验证APP扫描下方二维码',
};

const login_page = {
    'login.title': '欢迎使用阿拉丁',
    'login.google': '谷歌账号登陆',
    'login.email.placeholder': '输入密码...',
    'login.email.Invalid': '无效邮箱地址',
    'login.email.input.label': '注册邮箱',
    'login.password.placeholder': '输入密码...',
    'login.password.Invalid': '无效密码',
    'login.password.input.label': '登录密码',
    'login.btn': '登录',
    'login.footer.warning': '如忘记密码请联系管理员进行重置.',
    'login.modal2fa': '登录',
};

const self_information_page = {
    'self.tab.log': '日志',
    'self.tab.perference': '附加信息',
    'self.tab.account': '基本信息',
    'self.log.log_empty': '暂时没有收集到日志信息',
    'self.log.search': '搜索',

    'self.log.offline_status': '离线',
    'self.log.online_status': '在线',
    'self.log.table.title.onlineTime': '上线时间',
    'self.log.table.title.offlineTime': '离线时间',
    'self.log.table.title.status': '当前状态',

    'self.perfernce.perfernce_empty': '暂无信息',
    'self.email': '邮箱',
    'self.password': '密码',
    'self.name': '用户名',
    'self.mobile': '手机号',
    'self.2fa': '2FA',
    'self.welcomePhrase': '问候语',
    'self.upload': '点击上传头像',
};

const financial_product_config = {};
const coupon_config = {};
const modal2fa = {
    'modal2fa.title': '谷歌验证码',
    'modalubk.title': 'Yubico',
    'modalubk.inputAgain': '再次输入',
    'modal2fa.unable2fa': ' ',
    'modal2fa.unableubk': ' ',
    'modal2fa.contact': '谷歌验证设备意外丢失? 请联系管理员重置.',
    'modal2fa.ubkcontact': 'UBK设备意外丢失? 请联系管理员重置.',
};

const messageText = {
    requireText: '必填',
    EmailNotEmpty: '邮件不能为空',
    needEmailToLogin: '用户Email(使用邮箱登录)',
    OrganizationNotEmpty: '机构名不能为空',
    EnglishShortName: '英文简称，例如: Cobo',
    AdminNotEmpty: '管理员不能为空',
    UserNameTips: '用户名，例如: Heaven',
    ErrorTips: '提示信息',
};

const zhCN = {
    ...login_page,
    ...siderBar,
    ...adminTeamUser,
    ...cobo_wallet_page,
    ...activite_page,
    ...resetpassword_page,
    ...reset2fa_page,
    ...self_information_page,
    ...modal2fa,
    ...pending_approval,
    ...transaction_explorer,
    ...financial_product_config,
    ...coupon_config,
    // ...custodyOrganization,
    ...messageText,
    ...kycAudit,
    ...riskControl_ruleManagement,
    ...riskControl_menuEvent,
    // ...withdrawExploer,
    ...common,
    // ...suspendedManagement,
    ...publishUpdateMessage,
    // ...threePartyHostingApplication,
    // ...threePartyHostingList,
    // ...mpcWithdrawExploer,
    // ...superloop,
    // ...superloopExchange,
    // ...superloopExchange,
    ...blockchain,
    ...approvalSystem,
    // ...tightenRiskControl,
    ...custody,
    ...tss,
    ...tools,
    ...argus,
    ...kyt,
    ...mpcAutoSweeping,
    ...coboBonus,
};

export default zhCN;
