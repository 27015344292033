import siderBar from './siderBar';
import adminTeamUser from './admin/teamUser';
import kycAudit from './kycAudit';
import cobo_wallet_page from './cobo_wallet_page';
import pending_approval from './pending_approval';
import transaction_explorer from './transaction_explorer';
// import custodyOrganization from './custody/custodyOrganization';
import riskControl_ruleManagement from './riskControl_ruleManagement';
import riskControl_menuEvent from './riskControl_menuEvent';
// import withdrawExploer from './custody/withdrawExploer';
// import mpcWithdrawExploer from './custody/mpcWithdrawExploer';
// import custody_user from './custody_user';
import common from './common';
// import suspendedManagement from './custody/suspendedManagement';
import publishUpdateMessage from './coboSettings/publishUpdateMessage';
// import threePartyHostingApplication from './custody/threePartyHostingApplication';
// import threePartyHostingList from './custody/threePartyHostingList';
// import superloop from './custody/superloop';
// import superloopExchange from './custody/superloopExchange';
import blockchain from './blockchain';
import approvalSystem from './approvalSystem';
// import tightenRiskControl from './custody/tightenRiskControl';
import custody from './custody';
import tss from './tss';
import argus from './argus';
import tools from './tools';
import kyt from './kyt';
import mpcAutoSweeping from './mpcAutoSweeping';
import coboBonus from './coboBonus';

const activite_page = {
    'activite.title.inactive': 'Welcome to Cobo Aladdin',
    'activite.title.active': 'The user account is already activated, please log in.',
    'activite.title.expire': 'The link is invalid, please ask your admin to invite again.',
    'activite.title.smallTitle': 'Activate account',
    'activite.input.email': 'YOUR E-MAIL',
    'activite.input.inviteCode': 'INVITED CODE',
    'activite.input.password': 'YOUR PASSWORD',
    'activite.input.confirmPassword': 'CONFIRM PASSWORD',
    'activite.text.alreadyActivate': 'Already activate ?',

    'activite.bind_2fa.title': 'Welcome to Cobo Aladdin',
    'activite.bind_2fa.smallTitle': 'Two factor authentication',
    'activite.bind_2fa.moment': 'Scan QR code with Authentication App',
};

const resetpassword_page = {
    'resetpwd.title': 'Reset Password',
    'resetpwd.input.email': 'YOUR E-MAIL',
    'resetpwd.input.password': 'YOUR PASSWORD',
    'resetpwd.input.confirmPassword': 'CONFIRM PASSWORD',
};

const reset2fa_page = {
    'reset2fa.title': 'Reset 2FA',
    'reset2fa.input.email': 'YOUR E-MAIL',
    'reset2fa.input.password': 'YOUR PASSWORD',
    'reset2fa.input.confirmPassword': 'CONFIRM PASSWORD',

    'reset2fa.bind_2fa.title': 'Welcome to Cobo Aladdin',
    'reset2fa.bind_2fa.smallTitle': 'Two factor authentication',
    'reset2fa.bind_2fa.moment': 'Scan QR code with Authentication App',
};

const login_page = {
    'login.title': 'Welcome to Cobo Aladdin',
    'login.google': 'Google Login',
    'login.email.placeholder': 'Enter your Email...',
    'login.email.Invalid': 'Invalid email address',
    'login.email.input.label': 'E-MAIL',
    'login.password.placeholder': 'Enter your password...',
    'login.password.Invalid': 'Invalid password',
    'login.password.input.label': 'PASSWORD',
    'login.btn': 'Login',
    'login.footer.warning': 'Forget password? Contact admin to reset.',
    'login.modal2fa': 'Login',
};

const self_information_page = {
    'self.tab.log': 'Logs',
    'self.tab.perference': 'Perference',
    'self.tab.account': 'Account',
    'self.log.log_empty': 'No logs',
    'self.log.search': 'Search',
    'self.log.offline_status': 'Offline',
    'self.log.online_status': 'Online',
    'self.log.table.title.onlineTime': 'Online Time',
    'self.log.table.title.offlineTime': 'Offline Time',
    'self.log.table.title.status': 'Status',

    'self.perfernce.perfernce_empty': 'No Information',
    'self.email': 'Email',
    'self.password': 'Password',
    'self.name': 'Name',
    'self.mobile': 'Mobile',
    'self.2fa': '2FA',
    'self.welcomePhrase': 'Welcome  Phrase',
    'self.upload': 'Click to upload',
};

const financial_product_config = {};
const coupon_config = {};
const modal2fa = {
    'modal2fa.title': 'Authentication code',
    'modalubk.title': 'Yubico',
    'modalubk.inputAgain': 'Input again',
    'modal2fa.unable2fa': 'Unable to access your Two Factor Authentication device?',
    'modal2fa.unableubk': 'Unable to access your UBK device?',
    'modal2fa.ubkcontact': 'Contact your admin to reset..',
    'modal2fa.contact': 'Contact your admin to reset.',
};

const messageText = {
    requireText: 'Required',
    EmailNotEmpty: 'Email is required',
    needEmailToLogin: 'User`s email(unique ID for login)',
    OrganizationNotEmpty: 'Organization name is required',
    EnglishShortName: 'English short name, e.g. Cobo',
    AdminNotEmpty: 'Admin name is required',
    UserNameTips: 'User name, e.g. Heaven',
    ErrorTips: 'Error',
};

const enCN = {
    ...login_page,
    ...siderBar,
    ...adminTeamUser,
    ...cobo_wallet_page,
    ...activite_page,
    ...resetpassword_page,
    ...reset2fa_page,
    ...self_information_page,
    ...modal2fa,
    ...pending_approval,
    ...transaction_explorer,
    ...financial_product_config,
    ...coupon_config,
    // ...custodyOrganization,
    ...messageText,
    ...kycAudit,
    ...riskControl_ruleManagement,
    ...riskControl_menuEvent,
    ...common,
    // ...withdrawExploer,
    // ...suspendedManagement,
    ...publishUpdateMessage,
    // ...threePartyHostingApplication,
    // ...threePartyHostingList,
    // ...mpcWithdrawExploer,
    // ...superloop,
    // ...superloopExchange,
    ...blockchain,
    ...approvalSystem,
    // ...tightenRiskControl,
    ...custody,
    ...tss,
    ...tools,
    ...argus,
    ...kyt,
    ...mpcAutoSweeping,
    ...coboBonus,
};

export default enCN;
