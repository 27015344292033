const mpcAutoSweeping = {
    'aladdin.mpcAutoSweeping': 'MPC自动归集',
    'mpc.wallet': '归集钱包',
    'mpc.task': '归集任务',
    'mpc.taskId': '任务ID',
    'mpc.walletName': '钱包名称',
    'mpc.walletId': '钱包ID',
    'mpc.sweepingAddressAndAssets': '归集地址和资产',
    'mpc.nonSweepingAssets': '未归集资产',
    'mpc.totalAssets': '归集总资产',
    'mpc.coinAddress': '地址',
    'mpc.coinAssets': '资产',
    'mpc.viewAndTrigger': '查看&发起归集',
    'mpc.hasSweptTask': '是否存在归集任务',
    'mpc.trigger': '发起归集',
    'mpc.start.trigger.modal.content': 'Auth/Guard 审批通过后，可前往{sweepingTask}查看进度。',
    'mpc.sweepingStrategy': '钱包归集策略',
    'mpc.sweepingStrategy.threshold.title': '阈值归集',
    'mpc.sweepingStrategy.threshold.text': '入账金额大于{amount}美金，发起归集',
    'mpc.sweepingStrategy.scheduled.title': '定时归集',
    'mpc.sweepingStrategy.scheduled.text': '当时间到达{time}时，发起归集',
    'mpc.sweepingStrategy.balance.title': '余额归集',
    'mpc.sweepingStrategy.balance.text': '某个币种金额达到{amount}美金，发起归集',
    'mpc.sweepingAssets': '归集资产',
    'mpc.sweepingStatus': '归集状态',
    'mpc.initiator': '发起人',
    'mpc.transactionProgressStats': '交易进展统计',
    'mpc.sweeping': '归集中',
    'mpc.swept': '归集完成',
    'mpc.noSweeping': '无归集交易',
    'mpc.initiatedSweepingAmount': '发起归集交易数量',
    'mpc.transactionsInProgress': '交易进行中的笔数',
    'mpc.successfulTransactions': '交易成功笔数',
    'mpc.failedTransactions': '交易失败笔数',
    'mpc.viewTransaction': '归集交易',
    'mpc.strategy': '归集策略',
};

export default mpcAutoSweeping;
