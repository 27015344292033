import Notification from '@/components/notification';
import store from '../reduxConfig/store.js';

const INIT_MINUTES = 24;
const INIT_SECONDS = 59;

let requestPool = [];
let relaxThreadCount = 6;
const REQUEST_THREAD_TOTAL = 6;
async function regularApi(url, params, method = 'POST', header_params = {}, useform = false, responseType = 'json') {
    let headers = Object.assign({}, { Accept: 'application/json', 'Content-Type': 'application/json' }, header_params);
    if (window.localStorage.aladdin_session) {
        headers['X-ALADDIN-CODE'] = window.localStorage.aladdin_session;
    }
    let opts = {
        method: method,
        headers: headers,
    };
    if (method === 'GET') {
        if (params) {
            let paramsArray = [];
            // 拼接参数
            Object.keys(params).forEach((key) => paramsArray.push(key + '=' + encodeURIComponent(params[key])));
            if (paramsArray.length > 0) {
                if (url.search(/\?/) === -1) {
                    url += '?' + paramsArray.join('&');
                } else {
                    url += '&' + paramsArray.join('&');
                }
            }
        }
    } else {
        let body_data;
        if (useform) {
            body_data = new FormData();
            for (let k in params) {
                body_data.append(k, params[k]);
            }
        } else {
            body_data = JSON.stringify(params);
        }
        opts = Object.assign({}, opts, {
            body: body_data,
            credentials: 'same-origin',
        });
    }
    let res;
    try {
        res = await fetch(url, opts);
        if (res.status !== 200) {
            let result = await res.json();
            Notification.error(`${result?.error_message}`);
            return;
        }

        if (responseType === 'json') {
            res = await res.json();

            if (!res.success) {
                if (res.error_code === 11001) {
                    Notification.error('登录过期', '重新登录');
                    await window.localStorage.removeItem('aladdin_session');
                    await window.localStorage.removeItem('permissions');
                    // window.location.hash = '#/login';
                    window.location.hash = '/login';
                } else if (res.error_code === 19005) {
                    Notification.error('账号错误', '请联系管理员重新绑定UBK');
                    await window.localStorage.removeItem('aladdin_session');
                    await window.localStorage.removeItem('permissions');
                    // window.location.hash = '#/login';
                    window.location.hash = '/login';
                } else if (res.error_code === 19006) {
                    Notification.error('验证失败', '请重新输入UBK');
                } else {
                    Notification.error(res.error_message || '系统异常，稍后再试');
                }
            } else {
                store.dispatch({
                    type: 'RESET_SESSION_EXPIRE_TIME',
                    minutes: INIT_MINUTES,
                    seconds: INIT_SECONDS,
                });
            }
        } else if (responseType === 'blob') {
            res = await res.blob();
        }
    } catch (e) {
        console.log(e);
        Notification.error('系统异常，稍后再试');
        res = {};
    }
    return res;
}

export function promiseApi(url, params, method = 'POST', header_params = {}, useform = false, responseType = 'json') {
    let headers = Object.assign({}, { Accept: 'application/json', 'Content-Type': 'application/json' }, header_params);
    if (window.localStorage.aladdin_session) {
        headers['X-ALADDIN-CODE'] = window.localStorage.aladdin_session;
    }
    let opts = {
        method: method,
        headers: headers,
    };
    if (method === 'GET') {
        if (params) {
            let paramsArray = [];
            // 拼接参数
            Object.keys(params).forEach((key) => paramsArray.push(key + '=' + params[key]));
            if (paramsArray.length > 0) {
                if (url.search(/\?/) === -1) {
                    url += '?' + paramsArray.join('&');
                } else {
                    url += '&' + paramsArray.join('&');
                }
            }
        }
    } else {
        let body_data;
        if (useform) {
            body_data = new FormData();
            for (let k in params) {
                body_data.append(k, params[k]);
            }
        } else {
            body_data = JSON.stringify(params);
        }
        opts = Object.assign({}, opts, {
            body: body_data,
            credentials: 'same-origin',
        });
    }
    let res = fetch(url, opts);
    let result = new Promise((resolve, reject) => {
        res.then(async (response) => {
            let ret;
            if (response.status !== 200) {
                Notification.error(`response status: ${response.status}`);
            }
            if (responseType === 'json') {
                ret = await response.json();
                if (!ret.success) {
                    if (ret.error_code === 11001) {
                        Notification.error('登录过期', '重新登录');
                        await window.localStorage.removeItem('aladdin_session');
                        await window.localStorage.removeItem('permissions');
                        // window.location.hash = '#/login';
                        window.location.hash = '/login';
                    } else if (ret.error_code === 19006) {
                        Notification.error('验证失败', '请重新输入UBK');
                    } else {
                        Notification.error(ret.error_message || '系统异常，稍后再试');
                    }
                }
            } else if (responseType === 'blob') {
                ret = await response.blob();
            }
            resolve(ret);
        })
            .then((err) => {
                reject(err);
            })
            .catch(() => {
                Notification.error('系统异常，稍后再试');
            });
    });
    return result;
}

export function threadRegularApi(func) {
    // console.log('call threadRegularApi', requestPool.length)
    requestPool.push(func);
    fillAllThread();
}

function fillAllThread() {
    // console.log('call fillAllThread', relaxThreadCount, requestPool.length)
    while (relaxThreadCount > 0 && requestPool.length > 0) {
        let func = requestPool.shift();
        relaxThreadCount -= 1;
        let res = func();
        if (res && res instanceof Promise) {
            res.finally(() => {
                console.log('finally call', relaxThreadCount, requestPool.length);
                if (relaxThreadCount < REQUEST_THREAD_TOTAL) {
                    relaxThreadCount += 1;
                }
                if (requestPool.length > 0) {
                    fillAllThread();
                }
            });
        }
    }
}

export default regularApi;
