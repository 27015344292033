const coboBonus = {
    'coboBonus.commissionType': 'Commission Type',
    'coboBonus.commissionType.salesCommission': 'Sales Commission',
    'coboBonus.commissionType.csmPerformance': 'CSM Performance',
    'coboBonus.commissionType.bdPerformance': 'BD Performance',
    'coboBonus.commissionType.salesPerformance': 'Sales Performance',
    'coboBonus.downloadExcel': 'Download',
    'coboBonus.table.name': 'Name',
    'coboBonus.table.value': 'Value',
    'coboBonus.table.email': 'Email',
    'coboBonus.setPassword': 'Set File Password',
    'coboBonus.total': 'Total Value',
    'coboBouns.download.tooltip': 'Download is not supported when there is no data.',
};

export default coboBonus;
