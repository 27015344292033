const mpcAutoSweeping = {
    'aladdin.mpcAutoSweeping': 'MPC Auto Sweeping',
    'mpc.wallet': 'Sweeping Wallet',
    'mpc.task': 'Sweeping Task',
    'mpc.taskId': 'Task ID',
    'mpc.walletName': 'Wallet Name',
    'mpc.walletId': 'Wallet ID',
    'mpc.sweepingAddressAndAssets': 'Sweeping Address and Assets',
    'mpc.nonSweepingAssets': 'Not Sweeping Assets',
    'mpc.totalAssets': 'Total Sweeping Assets',
    'mpc.coinAddress': 'Address',
    'mpc.coinAssets': 'Assets',
    'mpc.viewAndTrigger': 'View&Trigger',
    'mpc.hasSweptTask': 'Sweeping Task Status',
    'mpc.trigger': 'Start Sweeping',
    'mpc.start.trigger.modal.content':
        'After the Auth/Guard approval, you can proceed to the {sweepingTask} to check the progress.',
    'mpc.sweepingStrategy': 'Wallet Sweeping Strategy',
    'mpc.sweepingStrategy.threshold.title': 'Threshold Sweeping',
    'mpc.sweepingStrategy.threshold.text': 'Initiate sweeping when the received amount exceeds {amount} USD.',
    'mpc.sweepingStrategy.scheduled.title': 'Scheduled Sweeping',
    'mpc.sweepingStrategy.scheduled.text': 'When the time reaches {time}, initiate sweeping.',
    'mpc.sweepingStrategy.balance.title': 'Balance Sweeping',
    'mpc.sweepingStrategy.balance.text': 'Initiate sweeping when the amount of a specific token reaches {amount} USD.',
    'mpc.sweepingAssets': 'Sweeping Assets',
    'mpc.sweepingStatus': 'Sweeping Status',
    'mpc.initiator': 'Creator',
    'mpc.transactionProgressStats': 'Transaction Progress Stats',
    'mpc.sweeping': 'Sweeping',
    'mpc.swept': 'Swept',
    'mpc.noSweeping': 'No Sweeping',
    'mpc.initiatedSweepingAmount': 'Initiated Sweeping Transactions',
    'mpc.transactionsInProgress': 'Ongoing Transactions',
    'mpc.successfulTransactions': 'Successful Transactions',
    'mpc.failedTransactions': 'Failed Transactions',
    'mpc.viewTransaction': 'Sweeping Transactions',
    'mpc.strategy': 'Sweeping Strategy',
};

export default mpcAutoSweeping;
